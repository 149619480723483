/* eslint-disable no-unused-vars */
// @ts-check
/** @typedef {import('./types').LinkListMap} LinkListMap */
/** @typedef {import('./types').GetPulldownLinks} GetPulldownLinks */
import { isAfter } from '@/libs/date.js';

/** @type {LinkListMap} */
const linkListMap = new Map([
    [
        'personal',
        ({ usingCampaign, isForeverFreeUser, isRestrictedUser }) => [
            [
                { link: '/user-setting', label: 'アカウント設定', role: ['*'] },
                {
                    link: '/subscriptions/payments',
                    label: 'プラン・お支払い情報',
                    role: ['*'],
                    hidden: usingCampaign || isForeverFreeUser,
                },
            ],
            [
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'], restricted: isRestrictedUser },
                { link: '/beginning-balance', label: '期首残高', role: ['*'], restricted: isRestrictedUser },
            ],
        ],
    ],
    [
        'cpta',
        ({ isCorporate, isCustomerSelected }) => [
            [{ link: '/cpta/customers', label: '顧客一覧', role: ['*'] }],
            [
                { link: '/cpta/cpta-office', label: '事務所情報', role: ['owner', 'admin'] },
                { link: '/cpta/payment/info', label: 'ご利用状況', role: ['owner'] },
                { link: '/user-setting', label: 'アカウント設定', role: ['*'] },
            ],
            isCustomerSelected
                ? [
                      { link: '/accounting-rule', label: '顧客会計方針', role: ['*'], hidden: !isCorporate },
                      {
                          link: '/trades-setting',
                          label: '時価優先通貨設定',
                          role: ['*'],
                      },
                      { link: '/beginning-balance', label: '期首残高', role: ['*'] },
                  ]
                : [],
        ],
    ],
    [
        'awa',
        (_) => [
            [
                { link: '/cpta/cpta-office', label: '会社情報', role: ['owner', 'admin'] },
                { link: '/user-setting', label: 'アカウント設定', role: ['*'] },
            ],
            [
                { link: '/accounting-rule', label: '会計方針', role: ['*'] },
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'] },
                { link: '/beginning-balance', label: '期首残高', role: ['*'] },
                { link: '/journal/beginning-balance', label: '仕訳期首残高', role: ['*'] },
                { link: '/daily-rate', label: '時価情報', role: ['*'] },
            ],
        ],
    ],
    [
        'corporate',
        ({ corporatePaymentLink }) => [
            [
                { link: '/cpta/cpta-office', label: '会社情報', role: ['owner', 'admin'] },
                {
                    link: corporatePaymentLink,
                    label: 'プラン・お支払い情報',
                    role: ['owner'],
                },
                { link: '/user-setting', label: 'アカウント設定', role: ['*'] },
            ],
            [
                { link: '/accounting-rule', label: '会計方針', role: ['*'] },
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'] },
                { link: '/beginning-balance', label: '期首残高', role: ['*'] },
            ],
        ],
    ],
    [
        'creator',
        (_) => [
            [{ link: '/user-setting', label: 'アカウント設定', role: ['*'] }],
            [
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'] },
                { link: '/beginning-balance', label: '期首残高', role: ['*'] },
            ],
        ],
    ],
    [
        'customer',
        (_) => [
            [
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'] },
                { link: '/beginning-balance', label: '期首残高', role: ['*'] },
            ],
        ],
    ],
    [
        'cpta_corporate',
        (_) => [
            [
                { link: '/accounting-rule', label: '会計方針', role: ['*'] },
                { link: '/trades-setting', label: '時価優先通貨設定', role: ['*'] },
                { link: '/beginning-balance', label: '期首残高', role: ['*'] },
            ],
        ],
    ],
]);

/** @type {GetPulldownLinks} */
export const getPulldownLinks = (accountType, role, context) => {
    const links = linkListMap.get(accountType)(context);
    return links.map((l) => l.filter(filterByRole(role))).filter((l) => l.length);
};

const filterByRole = (role) => (link) => link.role.some((r) => r === '*' || r === role) && !link.hidden;
